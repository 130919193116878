/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import custom fonts */
@font-face {
    font-family: "Poppins-Thin"; //This is what we are going to call
    src: url("./assets/fonts/Poppins-Thin.ttf");
}
@font-face {
    font-family: "Poppins-Regular"; //This is what we are going to call
    src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Poppins-Regular"; //This is what we are going to call
    src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Poppins-Bold"; //This is what we are going to call
    // src: url("../src/assets/fonts/Poppins-Bold.ttf");
    src: url("./assets/fonts/Poppins-Bold.ttf")
}

@import "stream-chat-angular/src/assets/styles/v2/scss/index.scss";

.blue-grad-page-bg {
    --background: url("./assets/images/three_beans.svg") 50% 50%;
}

ion-footer {
    padding-bottom: var(--ion-safe-area-bottom);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }